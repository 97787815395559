// Logo
$logo-file:                         url('../brand/logo-negativo.svg') !default;
$logo-height:                       54px !default;
$logo-width:                        155px !default;
$logo-transition:                   all 0.45s ease-in-out !default;
// Logo: footer
$logo-footer-max-width:             150px !default;
// Logo: On-Scroll
$logo-scroll-file:                  url('../brand/logo-negativo.svg') !default;
$logo-scroll-height:                35px !default;
$logo-scroll-width:                 110px !default;

// Icon
$icon-file:                         url('../brand/isotipo.svg') !default;
$icon-width:                        45px !default;
$icon-height:                       45px !default;

// Colors
$primary:                         #2150d1 !default;
$secondary:                       #3C3C3B !default;
$tertiary:                        #000000 !default;
$light:                           #EEEEEE !default;
$black:                           #000000 !default;
$dark:                            #111111 !default;
$white:                           #FFFFFF !default;
$pink:                            #E0245E !default;
// Colors: Social media
$facebook:                        #3B5998 !default;
$googleplus:                      #C32F10 !default;
$instagram:                       #E1306C !default;
$twitter:                         #00ACED !default;
$linkedin:                        #0077B5 !default;
$youtube:                         #FF0000 !default;
$whatsapp:                        #25D366 !default;

// Body
$body-bg:                           $white !default;
$body-color:                        yiq-contrast-color($body-bg) !default;

// Global
$global-bg:                         darken($body-bg, 5%) !default;
$global-color:                      yiq-contrast-color($global-bg)!default;

// Footer
$footer-bg:                         darken($secondary, 7.5%) !default;
$footer-color:                      yiq-contrast-color($footer-bg) !default;
$footer-color-a:                    rgba($footer-color, .65) !default;
$footer-color-a-hover:              darken($footer-color-a, 5%) !default;
$footer-color-social:               $white !default;

// Buttons
$btn-border-width: 2px;

// Card
$card-bg:                           $white !default;
$card-color:                        yiq-contrast-color($card-bg) !default;
$card-gutter:                       2rem !default;
$card-spacer-x:                     ($card-gutter - 0.5rem) !default;
$card-spacer-y:                     $card-gutter/2 !default;
$card-img-overlay-padding:          $card-gutter !default;
$card-columns-gap:                  $card-gutter !default;

// Close
$close-color:                       rgba(yiq-contrast-color($body-bg), .45) !default;
$close-text-shadow:                 0px 1px rgba(yiq-contrast-color($close-color), .75) !default;

// Dropdown
$dropdown-bg:                       lighten($body-bg, 2.5%)           !default;
$dropdown-color:                    yiq-contrast-color($dropdown-bg)  !default;
$dropdown-divider-bg:               rgba($dropdown-color, .1)         !default;

$dropdown-link-color:               yiq-contrast-color($dropdown-bg)  !default;
$dropdown-link-hover-color:         $dropdown-link-color              !default;
$dropdown-link-hover-bg:            lighten($dropdown-bg, 10%)        !default;

$dropdown-header-color:             rgba($dropdown-color, .25)        !default;

// Enables
$enable-responsive-font-sizes:      true !default;

// Fonts
$font-family-sans-serif:            "Google sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-heading-sans-serif:    "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// Fonts: directories
$material-design-icons-font-directory-path:   '../fonts/' !default;
$material-design-icons-font-path:             '../fonts/' !default;
$fa-font-path:                                '../fonts'  !default;

// Grid
$grid-gutter-width:                 $card-gutter !default;

// HR
$hr-border-color:                   rgba(yiq-contrast-color($body-bg), .1) !default;

// Icons
$icon-margin-side:                 .65rem !default;

// Input
$input-bg:                          darken($global-bg, 2.5%) !default;
$input-border-color:                darken($input-bg, 5%) !default;
$input-color:                       rgba(yiq-contrast-color($input-bg), .85) !default;
$input-placeholder-color:           rgba(yiq-contrast-color($input-bg), .5) !default;

// Jumbotron
$jumbotron-bg:                      darken($body-bg, 2.5%) !default;
$jumbotron-color:                   yiq-contrast-color($jumbotron-bg) !default;

// List group
$list-group-item-padding-y:         $grid-gutter-width/2 !default;
$list-group-item-padding-x:         $card-spacer-x !default;

// Modals
$modal-content-bg:                  $dropdown-bg !default;
$modal-header-border-color:         rgba(yiq-contrast-color($modal-content-bg), .075) !default;

// Mouse
$mouse-width:                       1.5rem !default;

// Navbar
$navbar-bg:                         #000 !default;
$navbar-padding-y:                  1rem !default;
$navbar-padding-x:                  1rem !default;
$navbar-color:                      yiq-contrast-color($navbar-bg) !default;
$navbar-a-color:                    yiq-contrast-color($navbar-bg) !default;
$navbar-expand:                     md !default;
$navbar-toggler:                    $primary !default;
// Navbar: Item (Links and buttons)
$navbar-item-font-family:           $font-family-heading-sans-serif !default;
$navbar-item-font-size:             1rem !default;
$navbar-item-font-weight:           500 !default;
// Navbar: On-Scroll
$navbar-scroll-bg:                  rgba($secondary, .95) !default;
$navbar-scroll-box-shadow:          0 .1rem .4rem rgba(#000, .05) !default;
$navbar-scroll-color:               yiq-contrast-color($navbar-scroll-bg) !default;
$navbar-scroll-a-color:             yiq-contrast-color($navbar-scroll-bg) !default;
$navbar-scroll-toggler:             $primary !default;

// Page's
// Page: 404
$page-404-bg:                       $dark !default;
$page-404-color:                    yiq-contrast-color($page-404-bg) !default;
// Page: Titles
$page-title-items:                  5 !default;
$page-title-font-weight:            300 !default;
$page-title-b-font-weight:          700 !default;

// Pagination
$pagination-bg-dark:                darken($body-bg, 6.5%) !default;
$pagination-bg-light:               lighten($body-bg, 5%) !default;
$pagination-border-color-dark:      darken($pagination-bg-dark, 6.5%) !default;
$pagination-border-color-light:     lighten($pagination-bg-light, 5%) !default;


@if yiq-is-light($body-bg) {
  $pagination-bg:                     $pagination-bg-dark !global;
  $pagination-disabled-color:         rgba(yiq-contrast-color($pagination-bg-dark), .35) !global;
  $pagination-border-color:           $pagination-border-color-dark !global;
  // Hover
  $pagination-hover-bg:               darken($pagination-bg-dark, 6%) !global;
  $pagination-hover-border-color:     darken($pagination-border-color, 6%) !global;
  // Active
  $pagination-active-border-color:    darken($pagination-border-color, 5%) !global;
  // Disabled
  $pagination-disabled-border-color:  darken($pagination-border-color, 2.5%) !global;
} @else {
  $pagination-bg:                     $pagination-bg-light !global;
  $pagination-disabled-color:         rgba(yiq-contrast-color($pagination-bg-light), .35) !global;
  $pagination-border-color:           $pagination-border-color-light !global;
  // Hover
  $pagination-hover-bg:               lighten($pagination-bg-dark, 4%) !global;
  $pagination-hover-border-color:     lighten($pagination-border-color, 4%) !global;
  // Active
  $pagination-active-bg:              lighten($pagination-bg-dark, 6%) !global;
  $pagination-active-border-color:    lighten($pagination-border-color, 6%) !global;
  // Disabled
  $pagination-disabled-border-color:  lighten($pagination-border-color, 2.5%) !global;
}

// Pagination: Colors
$pagination-color:                  rgba(yiq-contrast-color($primary), .85) !default;
$pagination-active-color:           rgba(yiq-contrast-color($primary), .85) !default;
$pagination-hover-color:            rgba(yiq-contrast-color($primary), .85) !default;
// Pagination: Disabled
$pagination-disabled-bg:            $pagination-bg !default;
$pagination-disabled-color:         rgba(yiq-contrast-color($pagination-bg), .35) !default;

// Table
$table-bg:                          $global-bg !default;
$table-border-color-dark:           darken($table-bg, 6.5%) !default;
$table-border-color-light:          lighten($table-bg, 5%) !default;
@if yiq-is-light($table-bg) {
  $table-border-color:              $table-border-color-dark !global;
} @else {
  $table-border-color:              $table-border-color-light !global;
}
$table-color:                       yiq-contrast-color($table-bg) !default;

// Talbe: Dark
$table-dark-bg:                     rgba(yiq-contrast-color($global-bg), .85) !default;
$table-dark-border-color:           rgba(yiq-contrast-color($global-bg), .15) !default;
$table-dark-color:                  yiq-contrast-color($table-dark-bg) !default;

// Text
$text-muted:                        rgba(yiq-contrast-color($body-bg), .4) !default;

// Theme-colors
$theme-colors: () !default;
$custom-colors: () !default;
// Theme-colors: Social media
$social-media: (
  facebook:   $facebook,
  googleplus: $googleplus,
  instagram:  $instagram,
  twitter:    $twitter,
  linkedin:   $linkedin,
  youtube:    $youtube,
  whatsapp:   $whatsapp
);

// Theme-colors: Merge
$theme-colors: map-merge(
  $theme-colors,
  $social-media,
);