/*!
 * Xolvex v1.1.2
 * Derian Castillo | hola@derianandre.com
 * Portafolio: http://derianandre.com/
 * Last revision: Derian Castillo
 * Time and date: 5:00 p.m. - 32/07/2019
 */

// YIQ
@import "sass-yiq/src/sass/yiq";

// Global variable overrides
@import "./variables";

// Andréstrap
@import "../../../scss/andrestrap";

// Plugins
@import "./plugins/geometryangle";
@import "./plugins/cocoen.min";

// Project style
@import "./style";