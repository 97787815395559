/*!
 * Andrestrap v1.5.3 (http://derianandre.com/)
 * No Copyright. Derian Castillo | hola@derianandre.com
 * Time and date: 5:10 p.m. - 23/07/2019
 */
// Global variables overrides
@import "./variables";

// Theme-colors: Merge
$theme-colors: map-merge(
  $theme-colors,
  $custom-colors,
);

// Bootstrap
@import "bootstrap/scss/bootstrap";

// André's Heart a.k.a Core
@import "core";
