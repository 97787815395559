.geometryangle-wrapper {
  position: relative;
  .geometryangle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh + $geometryangle-height;
    z-index: -2;
    @include media-breakpoint-down(sm) {
      height: 100vh;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -35vh;
      left: 0;
      width: 100%;
      height: $geometryangle-height + 80vh;
      background: linear-gradient(transparent 0%, $tertiary 35%, #1B2F62 75%, transparent 100%);
      z-index: -1;
    }
  }
}