/*
 * Overrides global variables
 */

 // Logo
$logo-file:                         url('../brand/logo-negativo.svg') !default;
$logo-height:                       45px !default;
$logo-width:                        160px !default;
$logo-transition:                   all 0.45s ease-in-out !default;
// Logo: footer
$logo-footer-max-width:             150px !default;
// Logo: On-Scroll
$logo-scroll-file:                  url('../brand/logo-negativo.svg') !default;
$logo-scroll-height:                45px !default;
$logo-scroll-width:                 160px !default;

// Icon
$icon-file:                         url('../brand/isotipo.svg') !default;
$icon-width:                        45px !default;
$icon-height:                       45px !default;

// Colors
$primary:                         #0988D0 !default;
$secondary:                       #542AEA !default;
$tertiary:                        #142341 !default;

// Global
$global-bg:                         lighten($tertiary, 5%);
$global-color:                      #FFF;

// Body
$body-bg:                           $tertiary;

// Footer
$footer-bg:                         transparent;

// Fonts
$font-family-sans-serif:            "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-heading-sans-serif:    "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-heading-serif:         "Lora", Georgia, "Times New Roman", Times, serif;

// Navbar
$navbar-bg:                         rgba($tertiary, .95) !default;
$navbar-padding-y:                  1rem !default;
$navbar-padding-x:                  1rem !default;
$navbar-color:                      yiq-contrast-color($navbar-bg) !default;
$navbar-expand:                     md;
// Navbar: Item (Links and buttons)
$navbar-item-font-family:           $font-family-heading-sans-serif;
$navbar-item-font-size:             1rem;
$navbar-item-font-weight:           500;
// Navbar: On-Scroll
$navbar-scroll-bg:                  rgba($tertiary, .95);
$navbar-scroll-box-shadow:          0 .2rem 0 rgba(#000, .35) !default;
$navbar-scroll-color:               yiq-contrast-color($tertiary) !default;

$input-bg:                          lighten($tertiary, 10%);
$input-group-addon-bg:              lighten($tertiary, 17.5%);
$input-border-color:                lighten($tertiary, 15%);

$card-bg:                           lighten($tertiary, 5%);

$geometryangle-height: 20vh;