.dataTables_wrapper {
  .dataTables_filter,
  .dataTables_length,
  .dataTables_info {
    padding: 0 !important;
  }
  .dataTables_info {
    white-space: normal !important;
  }

  select {
    margin: 0 0.25rem;
  }
  label {
    margin: 0;
  }
}

table.dataTable {
  thead {
    .sorting:after,
    .sorting:before,
    .sorting_asc:after,
    .sorting_asc:before,
    .sorting_asc_disabled:after,
    .sorting_asc_disabled:before,
    .sorting_desc:after,
    .sorting_desc:before,
    .sorting_desc_disabled:after,
    .sorting_desc_disabled:before {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.15rem;
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-feature-settings: 'liga';
      font-display: block;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
    }
    .sorting:before,
    .sorting_asc:before,
    .sorting_desc:before,
    .sorting_asc_disabled:before,
    .sorting_desc_disabled:before {
      right: .5rem;
      top: .25rem;
      content: 'keyboard_arrow_up';
    }
    .sorting:after,
    .sorting_asc:after,
    .sorting_desc:after,
    .sorting_asc_disabled:after,
    .sorting_desc_disabled:after {
      right: .5rem;
      bottom: .25rem;
      content: 'keyboard_arrow_down';
    }
    & > tr > td.sorting,
    & > tr > td.sorting_asc,
    & > tr > td.sorting_desc,
    & > tr > th.sorting,
    & > tr > th.sorting_asc,
    & > tr > th.sorting_desc {
      padding-right: 3rem;
      padding-left: 1rem;
    }
  }
} 




table {
  @include media-breakpoint-down(sm) {
    font-size: 75%;
  }
  tr {
    vertical-align: center;
  }
  th.col-actions {
    pointer-events: none;
  }
  .col-actions {
    text-align: right;
    padding-right: .75rem !important;
    min-width: 75px;
    &::after,
    &::before {
      display: none !important;
    }
    a,
    button {
      font-weight: 500;
      padding: .1rem .5rem !important;
      border-radius: 999rem;
      &.btn-circle.btn-sm {
        padding: .05rem !important;
        i {
          font-size: 1rem;
        }
      }
    }
  }
}