@keyframes bgGradientAnimation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes bgGradientAnimationY {
	0% {
		background-position: 50% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 50% 0%;
	}
}

@keyframes bgColorAnimationLogo {
  0% {
    background-image: url('../../brand/logo-negativo.svg');
  }

  25% {
    background-image: url('../../brand/logo-negativo.svg');
  }

  50% {
    background-image: url('../../brand/logo-negativo-c.svg');
  }

  100% {
    background-image: url('../../brand/logo-negativo.svg');
  }
}
@keyframes bgColorAnimation {
  0% {
    background: $secondary;
  }

  25% {
    background: $dark;
  }

  50% {
    background: $primary;
  }

  75% {
    background: $dark;
  }

  100% {
    background: $secondary;
  }
}
@keyframes page404 {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes page404before {
  50% {
    opacity: .5;
  }  100% {
    opacity: 1;
  }
}

@keyframes mouseMove {
  0%,
  20% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(.5rem);
  }
  80% {
    transform: translateY(0);
  }
}

@keyframes mouseScroll {
  0%, 
  20% {
		transform: translateY(0) scaleY(1);
	}
	100% {
		transform: translateY(1.5rem) scaleY(2);
		opacity: 0;
	}
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}