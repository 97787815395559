// Gogle fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

// Font-awesome 4.7.0
// https://fontawesome.com/v4.7.0/
// @import "../../node_modules/font-awesome/scss/font-awesome"; 

// Font-awesome 5.7.2
// https://fontawesome.com/
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome"; 
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/v4-shims";

// Material Design Icons DX
// https://jossef.github.io/material-design-icons-iconfont/
@import "../../node_modules/material-design-icons-iconfont/src/material-design-icons";