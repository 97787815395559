/*
 * Sidebar
 */
$sidebar-width: 250px;
.dashboard {
  background-color: #EEEEEE;
  .logo {
    h1 {
      color: #FFF;
      font-weight: 300;
      font-size: 1.25rem;
      text-align: right;
      margin: 0;
    }
  }
  .sidebar {
    background-color: $primary;
    width: $sidebar-width;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    display: block;
  
    ul {
      margin: 0;
    }
    hr {
      margin: 0 -2rem;
      border: 0;
      border-top: 1px solid rgba(0,0,0,0.05);
      border-bottom: 1px solid rgba(255,255,255,0.1);
    }
    &.navbar-scroll {
      padding: 2rem;
    }
    .container {
      padding: 0;
    }
    .collapse:not(.show) {
      display: block;
    }
    .nav-link {
      font-weight: 500;
      margin: 0;
    }
    .navbar-toggler {
      display: none;
    }
    .navbar-brand {
      margin-bottom: 1rem;
      img {
        height: 50px;
      }
    }
  }
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }
  .user-details {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    .profile-picture {
      width: 65px;
      height: 65px;
      border-radius: 100%;
    }
    .user-info {
      padding: 0 1rem;
    }
  }
  .content {
    width: calc(100% - #{$sidebar-width});
    padding: 2rem;
    margin-left: auto;
    .card {
      margin-bottom: 2rem;
      box-shadow: 0px 2px 2px 1px rgba(0,0,0,.05);
      h5, h6 {
        margin: 0;
      }
    }
    .card-columns {
      column-count: 4;
    }
  }
  .list-group-notifications {
    a.list-group-item,
    button.list-group-item {
      display: flex;
      align-items: center;
      padding: .65rem 1rem;
      text-decoration: none !important;
      color: #000;
      &:hover {
        background: linear-gradient(#fff, #f5f5f5);
      }
      i {
        margin-right: .75rem;
      }
      &.show-all {
        justify-content: center;
        text-align: center;
        font-weight: bold;
        font-size: 90%;
      }
    }
  }
  footer {
    background: transparent;
    color: #333;
    a {
      color: #777;
      &:hover {
        color: #777;
      }
    }
  }
}

// Responsive

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1500px) {
  .dashboard {
    .content {
      .card-columns {
        column-count: 2;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
  .dashboard {
    .content {
      .card-columns {
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  .dashboard {
    .logo {
      h1 {
        display: none;
      }
    }
  }
  .sidebar {
    background-color: rgba(52, 101, 175, .95);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: inherit;
    width: auto;
    height: auto;
    &.navbar-scroll {
      padding: 1rem;
    }
    .container {
      max-width: 100%;
    }
    .collapse:not(.show){
      display: none;
    }
    .navbar-toggler {
      display: block;
      padding: .25rem 1rem;
    }
    .navbar-brand {
      margin-bottom: 0;
    }
    .navbar-collapse {
      background-color: transparent;
    }
  }
  .dashboard {
    .content {
      padding-top: 7rem;
      margin-left: 0;
      width: 100%;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .dashboard {
    .content {
      .card-columns {
        column-count: 2;
      }
    }
  }
}


// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .dashboard {
    .content {
      .card-columns {
        column-count: 1;
      }
    }
  }
}
