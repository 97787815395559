html {
  overflow-x: hidden;
}
body {
  overflow: hidden;
  background: linear-gradient(#142341, #223A7E, #142341);
}
.navbar {
  .nav-item {
    .nav-link {
      justify-content: center;
    }
  }
}
.page-index, .page-index-en, .page-landing {
  overflow-x: hidden;
  .navbar {
    position: fixed;
    background: transparent;
  }
  .navbar-collapse-show,
  .navbar-scroll {
    background: $navbar-scroll-bg;
  }
}
.modal-cotizador {
  overflow: hidden;
  padding: 1rem !important;
  $modal-cotizador-border-radius: 2rem;
  .modal-dialog {
    margin: 0;
    min-height: 100%;
    min-width: 100%;
    overflow-y: hidden;
    @include media-breakpoint-down(sm) {
      align-items: flex-start;
    }
    .modal-content {      
      background: linear-gradient(180deg, #142341, #223A7E);
      position: relative;
      width: 100%;
      min-height: calc(100vh - 2rem);
      border-radius: $modal-cotizador-border-radius;
      transition: height .5s ease;
      .modal-header {
        border-radius: $modal-cotizador-border-radius $modal-cotizador-border-radius 0 0;
        border: 0;
        padding: 5rem 2rem 0 2rem;
        @include media-breakpoint-down(sm) {
          padding: 2rem 2rem 0 2rem;
        }
        .btn-close {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          @include media-breakpoint-down(sm) {            
            top: 1rem;
            right: 1rem;
            border: 0;
            i {
              font-size: 1rem;
            }
          }
        }
      }
      .modal-body {
        padding: 0;
        display: flex;
        align-items: stretch;
        justify-content: center;
        .modal-step {
          &.hide {
            animation: fadeOut 1s ease;
            display: none;
          }
          &.show {
            animation: fadeIn 1s ease;
            display: flex;
          }
          flex-direction: column;
          align-items: stretch;
          .modal-step-header {
            width: 100%;
          }
          .option {
          }
          .row {
            height: 100%;
            align-items: center;
            justify-content: center;
          }
        }
        h3 {
          margin: 2rem 0 3rem;
          width: 100%;
          text-align: center;
          font-weight: 800 !important;
          @include media-breakpoint-down(sm) {
            margin-bottom: 0;
            font-weight: 800 !important;
          }
        }
        .option,
        .option-select {
          background: transparent;
          color: #FFF;
          border: 0;
          flex-direction: column;
          display: flex;
          align-items: center;
          margin: 1rem 0;
          outline: 0;
          .icon {
            width: 150px;
            height: 150px;
            @include media-breakpoint-down(lg) {
              width: 125px;
              height: 125px;
            }
            @include media-breakpoint-down(sm) {
              width: 80px;
              height: 80px;
            }
            position: relative;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              z-index: 2;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 7rem;
              @include media-breakpoint-down(lg) {
                font-size: 5rem;
              }
              @include media-breakpoint-down(sm) {
                font-size: 3.5rem;
              }
              transition: all .5s ease;
            }
            &::before {
              z-index: 1;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              content: "";
              opacity: 0;
              background: linear-gradient(0deg, $secondary, $primary);
              border-radius: 100%;
              transform: scale(0);
              transition: all .5s ease;
            }
          }
          span {
            font-family: $font-family-heading-sans-serif;
            font-size: 1.75rem;
            transform: scale(1);
            transition: all .5s ease;
            @include media-breakpoint-down(lg) {
              font-size: 1.5rem;
            }
            @include media-breakpoint-down(sm) {
              font-size: 1rem;
              font-weight: 500;
            }
          }
          &:hover,
          &.active {
            .icon {
              i {
                transform: rotate3d(1, 2, -3, 360deg);
              }
              &::before {
                transform: scale(1);
                opacity: 1;
              }
            }
            span {
              transform: scale(1.15);
              font-weight: bold;
            }
          }
        }
      }
      .modal-footer {
        border-radius: 0 0 $modal-cotizador-border-radius $modal-cotizador-border-radius;
        border: 0;
        padding: 2em;
        button.btn {
          display: flex;
          align-items: center;
          background: lighten(#223A7E, 5%);
          @include media-breakpoint-down(sm) {
            i {
              margin: 0;
            }
            span {
              display: none;
            }
          }
        }
        .step {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 45px;
          height: 45px;
          background: lighten(#223A7E, 5%);
          text-align: center;
          border-radius: 999rem;
          font-family: $font-family-heading-sans-serif;
          font-size: 1.5rem;
          font-weight: 300;
        }
        .progress {
          margin: 0 .5rem;
          width: 500px;
          height: 45px;
          border-radius: 9999rem;
          background: lighten(#223A7E, 5%);
          padding: .35rem;
          .progress-bar {
            background: linear-gradient(90deg, darken($primary, 5%), lighten($primary, 15%));
            border-radius: 9999rem;
          }
        }
      }
    }
  }
}
section {
  &.servicios {
    .list-group-item {
      background: transparent;
      border-color: rgba(255, 255, 255, 0.1);
      border-top: 0;
    }
  }
  &.trabajos {
    a.card {
      color: #FFF;
    }
  }
  &.clients {
    padding: 5rem 0;
    .carousel {
      padding: 1rem 5rem;
      .carousel-control-prev,
      .carousel-control-next {
        width: 50px;
      }
      .carousel-item {
        opacity: 0;
        transition: transform .15s ease, opacity .35s ease-out;
        &.active {
          opacity: 1;
        }
      }
    }
    .carousel-clients img {
      filter: brightness(0) invert(1);
      margin: 0 auto;
      transition: filter .35s ease;
      &:hover {
        filter: brightness(0.5) sepia(1) hue-rotate(165deg) saturate(5);
      }
    }
  }
}
.platform {
  // Dashboard and Profile
  &.page-dashboard,
  &.page-profile {
    
    .navbar {
      position: fixed;
      background: transparent;
      &.navbar-scroll, &.navbar-collapse-show {
        background: $navbar-scroll-bg;
      }
    }
    &.page-business {
      .profile-header {
        background: linear-gradient(0deg, $primary 0%, darken($primary, 25%) 100%);
      }
    }
    &.page-xolver {
      .profile-header {
        background: linear-gradient(0deg, $secondary 0%, darken($secondary, 25%) 100%);
      }
    }
    .profile-header {
      background-position: top;
      position: relative;
      .container {
        padding-top: 10rem;
      }
      .profile-info {
        display: flex;
        flex-direction: column;        
        align-items: center;        
        margin-bottom: 2rem;  
        @include media-breakpoint-up(md) {  
          flex-direction: row;     
          .profile-avatar {
            height: 150px !important;
            width: 150px !important;
            margin-bottom: 0 !important;
            margin-right: 2rem;
          }
        }
        .profile-avatar {
          display: block;
          height: 125px;
          width: 125px;
          background-size: cover;
          background-position: top center;
          border-radius: 50%;
          margin-bottom: 2rem;
          box-shadow: 0px .5rem 2rem rgba(0,0,0,.25);
        }      
        .list-info {
          list-style: none;
          display: flex;
          align-items: center;
          margin: .5rem 0 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;            
            font-weight: 400;
            padding: 0 0.85rem;
            border-right: 1px solid rgba($white, 0.25);
            font-size: .95rem;
            &:first-child {
              padding-left: 0;
            }
            &:last-child  {
              padding-right: 0;
              border: 0;
            }
            .badge {
              border-radius: 100px;
            }
          }
        }
      }
      // Nav
      .nav-tabs-dashboard {
        border: 0;
        .nav-item {
          border: 0;
          color: $body-color;
        }
        .active {          
          background: $body-bg;
          color: $body-color;
        }
      }
    }
  }
}

.badge-verified {  
  $badge-verified-size: 20px;
  position: relative;
  font-size: ceil($badge-verified-size / 1.4);
  font-weight: 900;
  width: $badge-verified-size;
  height: $badge-verified-size;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba($primary, 1) 0%, rgba($secondary, 1) 100%);
  background-size: 100% 200%;
  border-radius: $badge-verified-size;
  box-shadow: 0 .25rem .5rem rgba($dark, 0.35);
  animation: badge 5s ease infinite;
  z-index: 2;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: pulse 3s ease infinite;
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0 0 3px 1px rgba(lighten($primary, 20%), 0.75);
  }
}

.trabajos {
  .card {
    transition: all .35s ease;
    &:hover {
      transform: perspective(500px) rotateX(3.5deg);
    }  
    .card-img {
      border-radius: 0;
    }
    .card-img-overlay {
      background: linear-gradient(15deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0) 50%);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      text-shadow: 0px 2px 2px rgba($dark, 0.5);
      border-radius: 0;
      .card-title {
        font-size: 1.25rem;
        font-weight: lighter;
        margin:  0;
      }
      .card-subtitle {
        font-size: 1.5rem;
        font-weight: bolder;
        margin:  0;
      }
    }
  }
}

hr {
  width: 100%;
}

article {
  .serif,
  .sans-serif {
    font-size: 2.5rem;
  }
  .serif {
    font-weight: 500;
    font-family: "Lora",Georgia,"Times New Roman",Times,serif;
  }
  .sans-serif {
    font-weight: 700;
    font-family: "Montserrat",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  }
  @include rfs(1.15rem);
  .container {
    display: flex;
    flex-direction: column;
  }
  .category {
    color: lighten($tertiary, 65%);
    letter-spacing: .15rem;
    font-weight: bold;
    text-transform: uppercase;
    @include rfs(1.5rem);
  }
  .title {
    margin: 1rem 0;
    font-weight: bolder;
    font-family: $font-family-heading-sans-serif;
    line-height: 3.5rem;
    @include rfs(3.75rem);
    background: linear-gradient(-30deg, lighten($primary, 20%) 0%, $primary 35%, lighten($secondary, 10%) 50%, $primary 65%, lighten($primary, 20%) 100%);
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: blogTitle 4s linear infinite;
    margin-bottom: 2.5rem;
  }
  .date {
    font-size: 1rem;
    font-weight: 400;
    color: lighten($tertiary, 35%)
  }
  p {
    .small {
      margin-top: 0;
    }
  }
  .img {
    align-self: center;
    margin: 2rem 2.5rem;
    position: relative;
    display: inline-block;
    width: auto;
    &.img-lg {
      
    }
    @include media-breakpoint-down(lg) {
      margin: 2rem 0rem !important;
    }
    img {
      max-width: 100%;
      border-radius: .25rem;
    }
    &.shadow-none {
      &::before {
        display: none !important;
      }
    }
    &::before {
      opacity: 0;
      visibility: hidden;
      transition: all .5s ease;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 1px 0px rgba($dark, .15), 0 .5rem 1rem .5rem rgba($dark, .5);
      transition: all .4s ease;
    }
    &:hover {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .img-article {
    width: 100%;
    position: relative;
    img {
      border-radius: 2.5rem 2.5rem 0 0;
      width: 100%;
      height: auto;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, $tertiary 0%, rgba($tertiary, .95) 15%, transparent 45%);
      z-index: 10;
    }
    &:before {
      display: none;
    }
  }
  .embed-responsive {
    max-width: 85%;
    margin: 4rem auto;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  p, ol, ul {
    font-weight: lighter;
    line-height: 1.8rem;
    margin: 2rem auto;
    max-width: 700px;
    display: block;
  }
  blockquote {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "“";
      color: $primary;
      font-weight: bolder;
      font-family: Georgia;
      font-size: 15rem;
      z-index: -1;
    }
    p {
      @include rfs(1.45rem);
      font-weight: 300;
      font-style: italic;
      padding-left: 2.5rem;
      color: rgba($dark, .75)
    }
    footer {
      font-size: 1rem;
      font-weight: 800;
    }
  }
}
.page-blog {
  .post {
    display: flex;
    position: relative;
    text-decoration: none;
    color: #000;
    align-items: stretch;
    transition: all .35s ease;
    width: 100%;
    height: 45vh;
    margin: 1rem 0;
    overflow: hidden;
    border-radius: 1rem;
    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s ease;
      color: #FFF;
      padding: 2rem 5rem;
      h2 {
        font-weight: bolder;
        font-family: $font-family-heading-sans-serif;
        @include rfs(3rem);
        background: linear-gradient(-30deg, lighten($primary, 20%) 0%, $primary 35%, lighten($secondary, 10%) 50%, $primary 65%, lighten($primary, 20%) 100%);
        background-size: 200% auto;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: blogTitle 4s linear infinite;
      }
    }
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      align-self: center;
      justify-self: center;
      z-index: -1;
      box-shadow: 0px 0px 15px rgba($dark, .5);
      transition: all 0.35s ease;
      img {
        filter: grayscale(1);
        opacity: .125;
        mix-blend-mode: color-dodge;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(35deg, rgba($tertiary, .85), transparent);
      z-index: -1;
    }
    &::before {
      opacity: 0;
      visibility: hidden;
      transition: all .35s ease;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($primary, 1);
      mix-blend-mode: color;
      z-index: 3;
    }
    &:hover {
      .info {
        text-shadow: 0px 2px 0px rgba($dark, 0.25);
        h2 {
          background: none;
          background-clip: none;
          text-fill-color: #FFF;
          -webkit-background-clip: none;
          -webkit-text-fill-color:#FFF;
          color: #FFF;
          animation: none;
        }
      }
      .image {
        animation: bgGradient 4s linear infinite;
        background: radial-gradient(circle, darken($secondary, 10%), $primary);
        background-size: 200% auto;
      }
    }
  }
}
.cocoen-drag {
  box-shadow: 0px 0px 5px rgba(0,0,0,.35);
  background: rgba(255,255,255,.15);
  border: 1.5px solid #FFF;
  &::before {
    border: 0;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
    width: 20px;
    background: rgba(130,130,130,.15);
    backdrop-filter: blur(5px);
    border-radius: 100%;
    box-shadow: 0px 0px 5px rgba(0,0,0,.25);
    border: 2px solid #FFF;
  }
}
@keyframes fade-opacity-img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .1;
  }
}
@keyframes fade-opacity-img-out {
  0% {
    opacity: .1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-opacity-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes badge {
	0% {
    background-position: 50% 0%;
	}
	50% {
    background-position: 50% 100%;
	}
	100% {
    background-position: 50% 0%;
	}
}
@keyframes pulse {
  10% {
    transform: scale(.95);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.2;
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
@keyframes blogTitle {
  to {
    background-position: 200% center;
  }
}
@keyframes bgGradient {
  25%{background-position:0 100%}
  50%{background-position:100% 100%}
  75%{background-position:100% 0}
}